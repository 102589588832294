import React from "react";

class Blank extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = { posts: [], years: [] };
  // }

  render() {
    return <></>;
  }
}

export default Blank;
